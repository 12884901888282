import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import zlib from 'react-zlib-js';

import { Container, Grid } from '@material-ui/core';
import {
  CloudDownloadTwoTone,
  CloudOff,
  CreateTwoTone,
  DeleteTwoTone,
  DesktopWindows,
  Memory,
  Save, ViewList,
  Web,
} from '@material-ui/icons';

import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { webViewerEndpoints } from '../../../config';
import { StudyDetailsDicomCard } from './StudyDetailsDicomCard';
import { StudyDetailsInfoCard } from './StudyDetailsInfoCard';
import { StudyDetailsReportsCard } from './StudyDetailsReportsCard';
import { DOWNLOAD_DICOM_STUDY } from '../../graphql/mutations';
import Loading from '../Common/Loading';
import { DownloadDicomCD, DownloadDicomFile } from '../../utils/DicomFileUtils';
import useStyles from './styled/StudyMakeStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { isEmpty } from '../../utils/ObjectUtils';
import { DeleteStudyDialog } from './modal/DeleteStudyDialog';
import { DeletePacsStudyDialog } from './modal/DeletePacsStudyDialog';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { PageContent } from '../Common/styled/PageContent';
import { getStudyApplicationPath } from './utils/StudyApplicationPath';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import { EditStudyDialog } from './modal/EditStudyDialog';

export default ({ study, errorMessage }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const currentHospital = useSelector((state) => state.hospital);

  const hospital = study && (study.hospital
    ? study.hospital
    : study.medicalCase && study.medicalCase.patient.hospital);

  const hospitalUuid = hospital && hospital.uuid;

  if (hospitalUuid && currentHospital && currentHospital.uuid !== hospitalUuid) {
    setHospitalEnvironment({ uuid: hospitalUuid, dispatch, context });
  }

  const usingWindows = navigator.appVersion.indexOf('Win') !== -1;
  const [keycloak] = useKeycloak();
  const [downloadDicomStudy, { loading: requesting }] = useMutation(DOWNLOAD_DICOM_STUDY);

  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const [showPacsDeleteModal, setShowPacsDeleteModal] = useState(false);
  const togglePacsDeleteModal = () => setShowPacsDeleteModal(!showPacsDeleteModal);

  const { medicalCase } = !isEmpty(study) && getStudyApplicationPath(study);

  const getWorkstationLink = () => {
    let params = 'messageName=OpenStudies';
    params += '&requestType=CONNECT';
    params += `&StudyUID=${study.studyInstanceUid}`;
    params += `&wadoUri=${process.env.GATSBY_WADO_URI}`;
    params += `&dicomWeb=${process.env.GATSBY_DICOM_WEB_URL}`;
    params += `&authURL=${process.env.GATSBY_KEYCLOAK_AUTH_URL}`;
    params += `&tokenURL=${process.env.GATSBY_KEYCLOAK_TOKEN_URL}`;
    params += `&refreshToken=${keycloak.refreshToken}`;

    const query = zlib.deflateSync(params).toString('base64');
    // const retrieveParams = zlib.inflateSync(new Buffer.from(query, 'base64')).toString();
    return `a3dcon://?ahp_enc=${query}`;
  };

  const isBrowser = () => typeof window !== 'undefined';

  const goToStudyList = () => {
    navigate('/studies');
  };

  const openInWorkstation = () => {
    if (!usingWindows) return;
    const url = getWorkstationLink();
    if (isBrowser) window.open(url, '_blank');
  };

  const openInWebViewer = () => {
    const url = `${webViewerEndpoints.getStudy}${study.studyInstanceUid}`;
    if (isBrowser) window.open(url, '_blank');
  };

  const downloadStudy = async () => {
    try {
      toast(t('requesting.study.download'), { className: 'toast-info' });
      await downloadDicomStudy({ variables: { uuid: study.uuid } });
      DownloadDicomFile(study, keycloak);
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) toast(errors.graphQLErrors[0].message, { className: 'toast-error' });
    }
  };

  const downloadStudyCD = async () => {
    try {
      toast(t('requesting.study.download'), { className: 'toast-info' });
      await downloadDicomStudy({ variables: { uuid: study.uuid } });
      DownloadDicomCD(study, keycloak);
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) toast(errors.graphQLErrors[0].message, { className: 'toast-error' });
    }
  };

  const windowsButton = usingWindows ? [
    { name: 'open.in.workstation', icon: DesktopWindows, handleClick: openInWorkstation },
  ] : [];

  const cdLiteButton = hospital && hospital.enableCdLiteDownload ? [
    { name: 'download.cd.lite', icon: Save, handleClick: downloadStudyCD, disabled: requesting },
  ] : [];

  const dcmRejectButton = hospital && hospital.enableDcmRejection ? [
    { name: 'remove.study.from.pacs', icon: CloudOff, handleClick: togglePacsDeleteModal, disabled: requesting },
  ] : [];

  const advancedToolButton = hospital && !!hospital.availableAdvancedTools.length ? [
    { name: 'divider2', type: 'divider' },
    { name: 'alma.advanced.tools', icon: Memory, handleClick: () => navigate(`/study/${study.uuid}/advanced-tools`) },
  ] : [];

  const buttons = [
    ...windowsButton,
    { name: 'open.in.viewer', icon: Web, handleClick: openInWebViewer },
    ...cdLiteButton,
    { name: 'download.study', icon: CloudDownloadTwoTone, handleClick: downloadStudy, disabled: requesting },
    ...advancedToolButton,
    { name: 'divider2', type: 'divider' },
    ...dcmRejectButton,
    { name: 'edit.study.info', icon: CreateTwoTone, handleClick: toggleEditModal },
    { name: 'delete.study', icon: DeleteTwoTone, I: 'delete', data: study, handleClick: toggleDeleteModal },
    { name: 'divider2', type: 'divider' },
    { name: 'back.study.list', icon: ViewList, data: study, handleClick: goToStudyList },
  ];

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  if (!study) return <Loading />;

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="study.info" items={buttons} breadcrumbs={getStudyApplicationPath(study)} />
        <EditStudyDialog open={showEditModal} onClose={toggleEditModal} study={study} />
        <DeleteStudyDialog open={showDeleteModal} onClose={toggleDeleteModal} study={study} medicalCase={medicalCase} />
        <DeletePacsStudyDialog open={showPacsDeleteModal} onClose={togglePacsDeleteModal} study={study} medicalCase={medicalCase} />
      </Navbar>
      <Container maxWidth="lg">
        <ScrollableContainer padding="1.25em 0 0">
          <PageContent>
            {study && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <StudyDetailsInfoCard study={study} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StudyDetailsDicomCard study={study} />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <StudyDetailsReportsCard study={study} />
                  </Grid>
                </Grid>
              </>
            )}
          </PageContent>
        </ScrollableContainer>
      </Container>
    </>
  );
};
